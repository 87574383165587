import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import Loader from "../Loader";
import moment from "moment";

export default function CheckInDetailsModal({ id, open, setOpen, setCheckinToRemove, setRemoveCheckinModalIsOpen }) {
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState(null);
    const [instructorName, setInstructorName] = useState(null);

    useEffect(() => {
        async function getCheckInDetails() {
            try {
                const response = await fetch(`https://api.bollywoodfunknyc.com/checkins.php?id=` + id);

                const result = await response.json();

                setData(result);
            } catch (err) {
                console.log(err);
            }
        }
        async function getInstructorName(instructor_id) {
            try {
                const response = await fetch(`https://api.bollywoodfunknyc.com/instructors.php?id=` + instructor_id);

                const result = await response.json();

                setInstructorName(result[0].full_name);
            } catch (err) {
                console.log(err);
            }
        }
        getCheckInDetails().then(() => {
            setLoading(false);
            data && data[0] && getInstructorName(data[0].instructor_id);
        });
    }, [id, data]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="pointer-events-auto relative w-96">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-500"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-500"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                                            <button
                                                type="button"
                                                className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                                onClick={() => setOpen(false)}
                                            >
                                                <span className="sr-only">Close panel</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="h-full overflow-y-auto bg-white p-8 relative">
                                        <div className="space-y-6 pb-16">
                                            {loading && <Loader />}
                                            {data && data[0] && (
                                                <>
                                                    <div className="flex items-start justify-between">
                                                        <div>
                                                            <h2 className="text-lg font-medium text-gray-900">
                                                                <span className="sr-only">Details for {id}</span>
                                                                {moment(data[0].timestamp).format("MMMM Do YYYY")}
                                                            </h2>
                                                            <p className="text-sm font-medium text-gray-500">
                                                                {moment(data[0].timestamp).format("h:mm:ss a")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h3 className="font-medium text-gray-900">Information</h3>
                                                        <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                                                            <div className="flex justify-between py-3 text-sm font-medium">
                                                                <dt className="text-gray-500">Instructor</dt>
                                                                <dd className="text-gray-900">{instructorName}</dd>
                                                            </div>
                                                            {/* <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">
                                                                Checked in by
                                                            </dt>
                                                            <dd className="text-gray-900">
                                                                Ayesha Khanna
                                                            </dd>
                                                        </div> */}
                                                        </dl>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="absolute bottom-8 right-8 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={() => {
                                                            setCheckinToRemove(id);
                                                            setRemoveCheckinModalIsOpen(true);
                                                        }}
                                                    >
                                                        Remove Check in
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
