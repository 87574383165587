import React from "react";
import { render } from "react-dom";
import { BrowserRouter, useNavigate } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        navigate((appState && appState.returnTo) || window.location.pathname);
    };

    return (
        <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
            {children}
        </Auth0Provider>
    );
};

const rootElement = document.getElementById("root");
render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth0ProviderWithRedirectCallback
                domain={process.env.REACT_APP_DOMAIN}
                clientId={process.env.REACT_APP_CLIENT_ID}
                redirectUri={window.location.origin}
            >
                <App />
            </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
    </React.StrictMode>,
    rootElement
);
