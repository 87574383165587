import React, { useState } from "react";

import { SearchIcon } from "@heroicons/react/solid";
const SearchBar = ({ data, setData }) => {
    const [query, setQuery] = useState("");
    function handleChange(e) {
        setQuery(e.target.value);
        getClients().then((clients) => {
            setData(clients);
        });
    }
    async function getClients() {
        try {
            const response = await fetch(
                `https://api.bollywoodfunknyc.com/clients.php?q=` + query
            );
            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <form className="w-full flex md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
                Search
            </label>

            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                    id="search-field"
                    className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                    placeholder="Search"
                    type="search"
                    value={query}
                    name="search"
                    onChange={handleChange}
                    onBlur={handleChange}
                    onFocus={handleChange}
                />
            </div>
        </form>
    );
};

export default SearchBar;
