import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Client from "./pages/Client";
import Clients from "./pages/Clients";
import Settings from "./pages/Settings";
import Loader from "./components/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import { ProtectedRoute } from "./components/ProtectedRoute";

export default function App() {
    const { isLoading, error } = useAuth0();

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            {error && <div>{error.message}</div>}
            <Routes>
                <Route
                    path="/"
                    element={<ProtectedRoute component={Clients} />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="/client/:id" element={<Client />} />
                <Route
                    path="/clients"
                    element={<ProtectedRoute component={Clients} />}
                />
                <Route
                    path="/settings"
                    element={<ProtectedRoute component={Settings} />}
                />
            </Routes>
        </>
    );
}
