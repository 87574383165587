import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import Checkbox from "./Checkbox";
import Loader from "../Loader";

export default function PlanView({
    isAuthenticated,
    subscription,
    setCancelSubModalIsOpen,
    setCheckInModalIsOpen,
    setSubscriptionToCancel,
    setOpenCheckInSlide,
    setSlideCheckInId,
}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [unChecked, setUnChecked] = useState(0);

    useEffect(() => {
        async function getCheckIns() {
            try {
                const response = await fetch(
                    `https://api.bollywoodfunknyc.com/checkins.php?subscription_id=${subscription.id}`
                );

                if (!response.ok) {
                    setError(response.status);
                    throw new Error(`Error! status: ${error}`);
                }
                setError(null);

                const result = await response.json();
                return result;
            } catch (err) {
                console.log(err);
            }
        }
        getCheckIns().then((user) => {
            setData(user);
            setUnChecked(subscription.plan_classes - data.length - 1);
            setLoading(false);
        });
    }, [subscription, error, data.length]);

    useEffect(() => {
        setUnChecked(subscription.plan_classes - data.length - 1);
    }, [data, subscription.plan_classes]);

    return (
        <>
            <div className="relative max-w-3xl mx-auto mb-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:p-8 bg-white p-6 shadow-md shadow-gray-200">
                <div className="-ml-4 -mt-4 items-center">
                    <div className="ml-4 mt-4 w-full">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {subscription.plan_name}
                        </h3>
                        <p className="text-sm text-gray-500">
                            Expires on {subscription.plan_expiry}
                        </p>
                    </div>
                    {loading && <Loader />}
                    {!loading && (
                        <div className="pt-4">
                            {[...Array(data.length)].map(
                                (elementInArray, index) => (
                                    <Checkbox
                                        key={index}
                                        style="checked"
                                        setOpenCheckInSlide={
                                            setOpenCheckInSlide
                                        }
                                        setSlideCheckInId={setSlideCheckInId}
                                        checkin_id={data[index].id}
                                    />
                                )
                            )}
                            {data.length < subscription.plan_classes && (
                                <Checkbox
                                    style="add"
                                    subscription_id={subscription.id}
                                    setCheckInModalIsOpen={
                                        setCheckInModalIsOpen
                                    }
                                    setSubscriptionToCancel={
                                        setSubscriptionToCancel
                                    }
                                />
                            )}
                            {data.length < subscription.plan_classes &&
                                [...Array(unChecked)].map(
                                    (elementInArray, index) => (
                                        <Checkbox key={index} />
                                    )
                                )}
                        </div>
                    )}
                    {isAuthenticated && (
                        <Menu
                            as="div"
                            className="flex-shrink-0 pr-2 absolute right-4 top-6"
                        >
                            <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                <span className="sr-only">Open options</span>
                                <DotsVerticalIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="z-10 mx-3 origin-top-right absolute right-2 top-8 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        "block px-4 py-2 text-sm w-full text-left"
                                                    )}
                                                    onClick={() => {
                                                        setSubscriptionToCancel(
                                                            subscription.id
                                                        );
                                                        setCancelSubModalIsOpen(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Cancel Subscription
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    )}
                </div>
            </div>
        </>
    );
}
