import { useState, React } from "react";
import axios from "axios";

export default function ClientForm({
    closeModal,
    renderToggle,
    setRenderToggle,
}) {
    const [btnText, setBtnText] = useState("Save");
    const [first_name, setFirstName] = useState("");
    const onFirstNameChange = (e) => setFirstName(e.target.value);
    const [last_name, setLastName] = useState("");
    const onLastNameChange = (e) => setLastName(e.target.value);
    const [email, setEmail] = useState("");
    const onEmailChange = (e) => setEmail(e.target.value);
    const [phone, setPhone] = useState("");
    const onPhoneChange = (e) => setPhone(e.target.value);
    const [instagram, setInstagram] = useState("");
    const onInstagramChange = (e) => setInstagram(e.target.value);
    function handleClick(e) {
        e.stopPropagation();
        setBtnText("Saving...");
        const headers = {
            "Content-Type": "text/plain",
        };

        var postData = { first_name, last_name, phone, email, instagram };
        axios
            .post("https://api.bollywoodfunknyc.com/clients.php", postData, {
                headers,
            })
            .then((res) => {
                setRenderToggle((renderToggle) => !renderToggle);
                closeModal();
                setTimeout(function () {
                    setBtnText("Save");
                }, 1000);
            });
    }
    return (
        <form>
            <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 sm:text-3xl sm:truncate">
                    Add a Client
                </h2>
            </div>
            <label
                htmlFor="first_name"
                className="pt-10 block text-sm font-medium text-gray-700"
            >
                First Name
            </label>
            <div className="mt-1">
                <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="First Name"
                    onChange={onFirstNameChange}
                    required
                />
            </div>

            <label
                htmlFor="last_name"
                className="pt-6 block text-sm font-medium text-gray-700"
            >
                Last Name
            </label>
            <div className="mt-1">
                <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Last Name"
                    onChange={onLastNameChange}
                    required
                />
            </div>

            <label
                htmlFor="phone"
                className="pt-6 block text-sm font-medium text-gray-700"
            >
                Phone
            </label>
            <div className="mt-1">
                <input
                    type="text"
                    name="phone"
                    id="phone"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="10 Digit Phone Number without hyphens (-)"
                    onChange={onPhoneChange}
                    required
                />
            </div>

            <label
                htmlFor="email"
                className="pt-6 block text-sm font-medium text-gray-700"
            >
                Email
            </label>
            <div className="mt-1">
                <input
                    type="email"
                    name="email"
                    id="email"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="you@example.com"
                    onChange={onEmailChange}
                    required
                />
            </div>

            <label
                htmlFor="email"
                className="pt-6 block text-sm font-medium text-gray-700"
            >
                Instagram Handle
            </label>
            <div className="mt-1">
                <input
                    type="text"
                    name="instagram"
                    id="instagram"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="eg. bollywoofunknyc"
                    onChange={onInstagramChange}
                    required
                />
            </div>

            <div className="mt-12 text-right">
                <button
                    type="button"
                    className="inline-flex mr-3 justify-center px-4 py-2 text-sm font-medium text-gray-900 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={handleClick}
                >
                    {btnText}
                </button>
            </div>
        </form>
    );
}
