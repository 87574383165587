import { CheckIcon, PlusIcon } from "@heroicons/react/outline";

export default function Checkbox({
    style,
    setCheckInModalIsOpen,
    setSubscriptionToCancel,
    subscription_id,
    setOpenCheckInSlide,
    setSlideCheckInId,
    checkin_id,
}) {
    if (style === "add") {
        return (
            <div
                className="p-4 inline-block"
                onClick={() => {
                    setSubscriptionToCancel(subscription_id);
                    setCheckInModalIsOpen(true);
                }}
            >
                <div className="bg-gray-300 p-6 md:p-8 rounded-full cursor-pointer hover:bg-gray-400">
                    <PlusIcon
                        className="h-6 w-6 text-gray-900"
                        aria-hidden="true"
                    />
                </div>
            </div>
        );
    } else if (style === "checked") {
        return (
            <>
                <div
                    className="p-4 inline-block"
                    onClick={() => {
                        setSlideCheckInId(checkin_id);
                        setOpenCheckInSlide(true);
                    }}
                >
                    <div className="bg-gray-700 p-6 md:p-8 rounded-full cursor-pointer">
                        <CheckIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                        />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <div className="p-4 inline-block">
                <div className="bg-gray-100 p-6 md:p-8 rounded-full">
                    <PlusIcon
                        className="h-6 w-6 text-gray-300"
                        aria-hidden="true"
                    />
                </div>
            </div>
        );
    }
}
