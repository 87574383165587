import { MailIcon, PhoneIcon, PlusIcon } from "@heroicons/react/solid";
import { useState, useEffect, React } from "react";
import { Link } from "react-router-dom";
import AddClientModal from "./AddClientModal";
import Loader from "../Loader";

export default function ClientsView({ data, setData }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [renderToggle, setRenderToggle] = useState(false);

    const [addClientModalIsOpen, setAddClientModalIsOpen] = useState(false);

    useEffect(() => {
        async function getClients() {
            try {
                const response = await fetch(
                    `https://api.bollywoodfunknyc.com/clients.php`
                );

                if (!response.ok) {
                    setError(response.status);
                    throw new Error(`Error! status: ${error}`);
                }
                setError(null);

                const result = await response.json();
                if (result) {
                    setLoading(false);
                }
                return result;
            } catch (err) {
                console.log(err);
            }
        }
        getClients().then((user) => {
            setData(user);
        });
    }, [error, renderToggle, setData]);

    return (
        <>
            {loading && <Loader />}
            {error && (
                <div>{`There is a problem fetching data - ${error}`}</div>
            )}
            {data && (
                <>
                    <AddClientModal
                        isOpen={addClientModalIsOpen}
                        setIsOpen={setAddClientModalIsOpen}
                        renderToggle={renderToggle}
                        setRenderToggle={setRenderToggle}
                    />
                    <button
                        type="button"
                        onClick={() => setAddClientModalIsOpen(true)}
                        className="inline-flex fixed bottom-10 right-10 z-10 shadow-lg px-5 py-3 border border-transparent text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        <PlusIcon
                            className="-ml-1 mr-2 h-5 w-5 text-white"
                            aria-hidden="true"
                        />
                        Add Client
                    </button>
                    {data.map(
                        (
                            {
                                id,
                                first_name,
                                last_name,
                                phone,
                                email,
                                instagram,
                            },
                            k
                        ) => (
                            <Link to={"/client/" + id} key={k}>
                                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                                    <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                                        <div className="ml-4 mt-4">
                                            <div className="ml-4">
                                                <h3 className="text-lg leading-6 font-bold text-gray-900">{`${first_name} ${last_name}`}</h3>
                                                <p className="text-sm text-gray-500">
                                                    {phone && (
                                                        <>
                                                            <a
                                                                href={
                                                                    `tel:+1 ` +
                                                                    phone
                                                                }
                                                                className="text-gray-900 hover:text-blue-700"
                                                            >
                                                                +1 {phone}
                                                            </a>
                                                            &nbsp;/&nbsp;
                                                        </>
                                                    )}
                                                    <a
                                                        href={"mailto:" + email}
                                                        className="text-gray-900 hover:text-blue-700"
                                                    >
                                                        {email}
                                                    </a>
                                                    {instagram && (
                                                        <a
                                                            href={
                                                                "https://www.instagram.com/" +
                                                                instagram
                                                            }
                                                            className="text-gray-900 hover:text-blue-700"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            &nbsp;/ IG:{" "}
                                                            {instagram}
                                                        </a>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="ml-4 mt-4 flex-shrink-0 flex">
                                            <a
                                                href={"tel:" + phone}
                                                type="button"
                                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                <PhoneIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </a>
                                            <a
                                                href={"mailto:" + email}
                                                type="button"
                                                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            >
                                                <MailIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    )}
                </>
            )}
        </>
    );
}
