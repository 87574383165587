import { useState, useEffect, React, Fragment } from "react";
import AddPlanModal from "./AddPlanModal";
import Loader from "../Loader";
import { DotsVerticalIcon, PlusIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import DeletePlanModal from "./DeletePlanModal";

export default function PlansView() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [renderToggle, setRenderToggle] = useState(false);

    const [planToDelete, setPlanToDelete] = useState(0);

    const [addPlanModalIsOpen, setAddPlanModalIsOpen] = useState(false);
    const [deletePlanModalIsOpen, setDeletePlanModalIsOpen] = useState(false);

    useEffect(() => {
        async function getPlans() {
            try {
                const response = await fetch(
                    `https://api.bollywoodfunknyc.com/plans.php`
                );
                if (!response.ok) {
                    setError(response.status);
                    throw new Error(`Error! status: ${error}`);
                }
                setError(null);
                const result = await response.json();
                if (result) {
                    setLoading(false);
                }
                return result;
            } catch (err) {
                console.log(err);
            }
        }
        getPlans().then((plans) => {
            setData(plans);
        });
    }, [error, renderToggle]);

    return (
        <>
            {loading && <Loader />}
            {error && (
                <div>{`There is a problem fetching the post data - ${error}`}</div>
            )}
            <AddPlanModal
                isOpen={addPlanModalIsOpen}
                setIsOpen={setAddPlanModalIsOpen}
                renderToggle={renderToggle}
                setRenderToggle={setRenderToggle}
            />
            <DeletePlanModal
                isOpen={deletePlanModalIsOpen}
                setIsOpen={setDeletePlanModalIsOpen}
                plan_id={planToDelete}
                renderToggle={renderToggle}
                setRenderToggle={setRenderToggle}
            />
            <button
                type="button"
                onClick={() => {
                    setAddPlanModalIsOpen(true);
                }}
                className="inline-flex fixed bottom-10 right-10 shadow-lg px-5 py-3 border border-transparent text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                <PlusIcon
                    className="-ml-1 mr-2 h-5 w-5 text-white"
                    aria-hidden="true"
                />
                Add Plan
            </button>
            {data &&
                data.map((plan, index) => (
                    <div className="relative p-4 bg-white border-b" key={index}>
                        <div className="ml-4">
                            <h3 className="text-md font-medium">{plan.name}</h3>
                            <div className="mt-1 text-sm">
                                <p>Number of classes: {plan.no_of_classes}</p>
                            </div>
                        </div>
                        <Menu
                            as="div"
                            className="flex-shrink-0 pr-2 absolute right-4 top-6"
                        >
                            <Menu.Button className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                <span className="sr-only">Open options</span>
                                <DotsVerticalIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="z-10 mx-3 origin-top-right absolute right-2 top-8 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        "block px-4 py-2 text-sm w-full text-left"
                                                    )}
                                                    onClick={() => {
                                                        setPlanToDelete(
                                                            plan.id
                                                        );
                                                        setDeletePlanModalIsOpen(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Delete Plan
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                ))}
        </>
    );
}
