import { ClipboardCopyIcon, PlusIcon } from "@heroicons/react/solid";
import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import EditClientModal from "./EditClientModal";
import PlanCard from "./PlanCard";
import Notification from "../Notification";
import AddSubscriptionModal from "./AddSubscriptionModal";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import DeleteClientModal from "./DeleteClientModal";
import CheckInModal from "./CheckInModal";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import { useAuth0 } from "@auth0/auth0-react";
import CheckInDetailsModal from "./CheckInDetailsModal";
import RemoveCheckinModal from "./RemoveCheckinModal";

function ClientView() {
    const [loading, setLoading] = useState(true);
    const [renderToggle, setRenderToggle] = useState(false);

    const [data, setData] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);

    const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);
    const [checkinToRemove, setCheckinToRemove] = useState(null);
    const [slideCheckInId, setSlideCheckInId] = useState(null);

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [cancelSubModalIsOpen, setCancelSubModalIsOpen] = useState(false);
    const [checkInModalIsOpen, setCheckInModalIsOpen] = useState(false);
    const [openCheckInSlide, setOpenCheckInSlide] = useState(false);
    const [showNot, setShowNot] = useState(false);
    const [removeCheckinModalIsOpen, setRemoveCheckinModalIsOpen] = useState(false);

    const { isAuthenticated } = useAuth0();

    const { id } = useParams();

    useEffect(() => {
        async function getUser() {
            try {
                const response = await fetch(`https://api.bollywoodfunknyc.com/clients.php?id=` + id);

                const result = await response.json();

                setData(result);
            } catch (err) {
                console.log(err);
            }
        }
        async function getSubscriptions() {
            try {
                const response = await fetch(`https://api.bollywoodfunknyc.com/subscriptions.php?id=` + id);

                const result = await response.json();
                if (result) {
                    setLoading(false);
                    setSubscriptions(result);
                }
                return result;
            } catch (err) {
                console.log(err);
            }
        }
        getUser().then(() => {
            getSubscriptions();
        });
    }, [id, renderToggle]);

    return (
        <>
            {loading && <Loader />}
            {!loading && data && (
                <main className="flex-1 p-6 lg:p-10">
                    {addModalIsOpen && (
                        <AddSubscriptionModal
                            client_id={id}
                            isOpen={addModalIsOpen}
                            setIsOpen={setAddModalIsOpen}
                            renderToggle={renderToggle}
                            setRenderToggle={setRenderToggle}
                        />
                    )}
                    {editModalIsOpen && data[0] && (
                        <EditClientModal
                            client_data={data[0]}
                            isOpen={editModalIsOpen}
                            setIsOpen={setEditModalIsOpen}
                            renderToggle={renderToggle}
                            setRenderToggle={setRenderToggle}
                        />
                    )}
                    {cancelSubModalIsOpen && (
                        <CancelSubscriptionModal
                            subscription_id={subscriptionToCancel}
                            isOpen={cancelSubModalIsOpen}
                            setIsOpen={setCancelSubModalIsOpen}
                            renderToggle={renderToggle}
                            setRenderToggle={setRenderToggle}
                        />
                    )}
                    {checkInModalIsOpen && (
                        <CheckInModal
                            subscription_id={subscriptionToCancel}
                            client_id={id}
                            isOpen={checkInModalIsOpen}
                            setIsOpen={setCheckInModalIsOpen}
                            renderToggle={renderToggle}
                            setRenderToggle={setRenderToggle}
                        />
                    )}
                    {deleteModalIsOpen && (
                        <DeleteClientModal
                            client_id={id}
                            isOpen={deleteModalIsOpen}
                            setIsOpen={setDeleteModalIsOpen}
                            renderToggle={renderToggle}
                            setRenderToggle={setRenderToggle}
                        />
                    )}
                    {showNot && (
                        <Notification
                            title="Link copied to keyboard!"
                            body="You can share this link with client."
                            show={showNot}
                            setShow={setShowNot}
                        />
                    )}
                    {openCheckInSlide && slideCheckInId && (
                        <CheckInDetailsModal
                            id={slideCheckInId}
                            open={openCheckInSlide}
                            setOpen={setOpenCheckInSlide}
                            setCheckinToRemove={setCheckinToRemove}
                            setRemoveCheckinModalIsOpen={setRemoveCheckinModalIsOpen}
                        />
                    )}
                    {removeCheckinModalIsOpen && checkinToRemove && (
                        <RemoveCheckinModal
                            checkin_id={checkinToRemove}
                            isOpen={removeCheckinModalIsOpen}
                            setIsOpen={setRemoveCheckinModalIsOpen}
                            renderToggle={renderToggle}
                            setRenderToggle={setRenderToggle}
                        />
                    )}
                    <div className="relative max-w-3xl mx-auto mb-12 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                        <div className="flex items-center space-x-5">
                            <div>
                                <h2 className="text-3xl font-bold text-gray-900">
                                    {data[0].first_name + " " + data[0].last_name}
                                </h2>
                                {isAuthenticated && (
                                    <p className="text-sm text-gray-500">
                                        {data[0].phone && (
                                            <>
                                                <a
                                                    href={`tel:+1 ` + data[0].phone}
                                                    className="text-gray-900 hover:text-blue-700"
                                                >
                                                    +1 {data[0].phone}
                                                </a>
                                                &nbsp;/&nbsp;
                                            </>
                                        )}
                                        <a
                                            href={"mailto:" + data[0].email}
                                            className="text-gray-900 hover:text-blue-700"
                                        >
                                            {data[0].email}
                                        </a>
                                        {data[0].instagram && (
                                            <a
                                                href={"https://www.instagram.com/" + data[0].instagram}
                                                className="text-gray-900 hover:text-blue-700"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                &nbsp;/ IG: {data[0].instagram}
                                            </a>
                                        )}
                                    </p>
                                )}
                            </div>
                        </div>
                        {isAuthenticated && (
                            <>
                                <div className="mt-6 pr-0 md:pr-8 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                                    <button
                                        type="button"
                                        className="inline-flex items-center justify-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                                        onClick={() => {
                                            copy(window.location.href);
                                            setShowNot(true);
                                        }}
                                    >
                                        <ClipboardCopyIcon
                                            className="h-5 w-5 -ml-1 mr-2 text-gray-600"
                                            aria-hidden="true"
                                        />
                                        Copy Link
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={() => {
                                            setAddModalIsOpen(true);
                                        }}
                                    >
                                        <PlusIcon className="-ml-1 mr-2 h-5 w-5 text-white" aria-hidden="true" />
                                        Add Subscription
                                    </button>
                                </div>
                                <Menu as="div" className="flex-shrink-0 pr-2 absolute -right-2 top-2.5">
                                    <Menu.Button className="w-8 h-8 inline-flex items-center justify-center text-gray-500 rounded-full hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                                        <span className="sr-only">Open options</span>
                                        <DotsVerticalIcon className="w-5 h-5" aria-hidden="true" />
                                    </Menu.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="z-10 mx-3 origin-top-right absolute right-2 top-12 w-48 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            className={classNames(
                                                                active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                                "block px-4 py-2 text-sm w-full text-left"
                                                            )}
                                                            onClick={() => {
                                                                setEditModalIsOpen(true);
                                                            }}
                                                        >
                                                            Edit Profile
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            className={classNames(
                                                                active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                                                "block px-4 py-2 text-sm w-full text-left"
                                                            )}
                                                            onClick={() => {
                                                                setDeleteModalIsOpen(true);
                                                            }}
                                                        >
                                                            Delete Client
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </>
                        )}
                    </div>
                    {subscriptions &&
                        subscriptions.map((subscription, k) => (
                            <PlanCard
                                key={k}
                                isAuthenticated={isAuthenticated}
                                client_id={id}
                                subscription={subscription}
                                setCancelSubModalIsOpen={setCancelSubModalIsOpen}
                                setCheckInModalIsOpen={setCheckInModalIsOpen}
                                setSubscriptionToCancel={setSubscriptionToCancel}
                                setOpenCheckInSlide={setOpenCheckInSlide}
                                setSlideCheckInId={setSlideCheckInId}
                            />
                        ))}
                    {subscriptions < 1 && (
                        <div className="text-center border-dashed border-2 p-20">
                            <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    vectorEffect="non-scaling-stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                />
                            </svg>
                            <h3 className="mt-2 text-sm font-medium text-gray-900">No subscriptions</h3>
                            <p className="mt-1 text-sm text-gray-500">Get started by adding a new subscription.</p>
                            <div className="mt-6"></div>
                        </div>
                    )}
                </main>
            )}
        </>
    );
}

export default ClientView;
