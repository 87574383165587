import { useState, useEffect, Fragment, React } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";

export default function AddSubscriptionModal({
    client_id,
    isOpen,
    setIsOpen,
    renderToggle,
    setRenderToggle,
}) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [planObject, setPlanObject] = useState("");
    const [btnText, setBtnText] = useState("Save");
    const [plan_expiry, setExpiryDate] = useState("");
    const onPlanObjectChange = (e) => setPlanObject(e.target.value);
    const onExpiryDateChange = (e) => setExpiryDate(e.target.value);
    function handleClick(e) {
        e.stopPropagation();
        setBtnText("Saving...");
        const postPlan = JSON.parse(planObject);
        const plan_name = postPlan.name;
        const plan_classes = postPlan.no_of_classes;

        const headers = {
            "Content-Type": "text/plain",
        };

        var postData = { client_id, plan_name, plan_expiry, plan_classes };
        axios
            .post(
                "https://api.bollywoodfunknyc.com/subscriptions.php",
                postData,
                {
                    headers,
                }
            )
            .then((res) => {
                setRenderToggle((renderToggle) => !renderToggle);
                setIsOpen(false);
                setTimeout(function () {
                    setBtnText("Save");
                }, 1000);
            });
    }
    useEffect(() => {
        async function getPlans() {
            try {
                const response = await fetch(
                    `https://api.bollywoodfunknyc.com/plans.php`
                );

                if (!response.ok) {
                    setError(response.status);
                    throw new Error(`Error! status: ${error}`);
                }
                setError(null);

                const result = await response.json();
                return result;
            } catch (err) {
                console.log(err);
            }
        }
        getPlans().then((plans) => {
            setData(plans);
        });
    }, [error, isOpen]);

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={() => {
                        setIsOpen(false);
                    }}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <form>
                                    <div className="flex-1 min-w-0">
                                        <h2 className="text-2xl font-bold leading-7 sm:text-3xl sm:truncate">
                                            Add Subscription
                                        </h2>
                                    </div>

                                    <div className="pt-8">
                                        <label
                                            htmlFor="plan"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Choose a Plan
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="planObject"
                                                name="planObject"
                                                autoComplete="plan-name"
                                                onChange={onPlanObjectChange}
                                                required
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                            >
                                                <option></option>
                                                {data &&
                                                    data.map((plan) => (
                                                        <option
                                                            key={plan.id}
                                                            value={JSON.stringify(
                                                                plan
                                                            )}
                                                        >
                                                            {plan.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="pt-4">
                                        <label
                                            htmlFor="date"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            When should the plan expire?
                                        </label>
                                        <div className="mt-1 relative rounded-md shadow-sm">
                                            <input
                                                type="date"
                                                name="plan_expiry"
                                                id="plan_expiry"
                                                onChange={onExpiryDateChange}
                                                required
                                                className="focus:border-indigo-500 block w-full px-4 pt-3 sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-12 text-right">
                                        <button
                                            type="button"
                                            className="inline-flex mr-3 justify-center px-4 py-2 text-sm font-medium text-gray-900 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                            onClick={() => {
                                                setIsOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={handleClick}
                                        >
                                            {btnText}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
