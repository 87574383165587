import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import axios from "axios";

export default function EditClient({
    client_data,
    isOpen,
    setIsOpen,
    renderToggle,
    setRenderToggle,
}) {
    function closeModal() {
        setIsOpen(false);
    }

    const [btnText, setBtnText] = useState("Save Changes");
    const [first_name, setFirstName] = useState(client_data.first_name);
    const onFirstNameChange = (e) => setFirstName(e.target.value);
    const [last_name, setLastName] = useState(client_data.last_name);
    const onLastNameChange = (e) => setLastName(e.target.value);
    const [email, setEmail] = useState(client_data.email);
    const onEmailChange = (e) => setEmail(e.target.value);
    const [phone, setPhone] = useState(client_data.phone);
    const onPhoneChange = (e) => setPhone(e.target.value);
    const [instagram, setInstagram] = useState(client_data.instagram);
    const onInstagramChange = (e) => setInstagram(e.target.value);
    const client_id = client_data.id;

    function handleClick(e) {
        e.stopPropagation();
        setBtnText("Saving...");
        const headers = {
            "Content-Type": "text/plain",
        };

        var postData = {
            client_id,
            first_name,
            last_name,
            phone,
            email,
            instagram,
        };
        axios
            .post("https://api.bollywoodfunknyc.com/clients.php", postData, {
                headers,
            })
            .then((res) => {
                setRenderToggle((renderToggle) => !renderToggle);
                closeModal();
                setTimeout(function () {
                    setBtnText("Save Changes");
                }, 1000);
            });
    }

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <form>
                                    <div className="flex-1 min-w-0">
                                        <h2 className="text-2xl font-bold leading-7 sm:text-3xl sm:truncate pb-10">
                                            Edit Profile
                                        </h2>
                                    </div>
                                    <label
                                        htmlFor="first_name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        First Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="First Name"
                                            onChange={onFirstNameChange}
                                            required
                                            defaultValue={
                                                client_data.first_name
                                            }
                                        />
                                    </div>

                                    <label
                                        htmlFor="last_name"
                                        className="pt-6 block text-sm font-medium text-gray-700"
                                    >
                                        Last Name
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="Last Name"
                                            onChange={onLastNameChange}
                                            required
                                            defaultValue={client_data.last_name}
                                        />
                                    </div>

                                    <label
                                        htmlFor="phone"
                                        className="pt-6 block text-sm font-medium text-gray-700"
                                    >
                                        Phone
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="xxx-xxx-xxxx"
                                            onChange={onPhoneChange}
                                            required
                                            defaultValue={client_data.phone}
                                        />
                                    </div>

                                    <label
                                        htmlFor="email"
                                        className="pt-6 block text-sm font-medium text-gray-700"
                                    >
                                        Email
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="you@example.com"
                                            onChange={onEmailChange}
                                            required
                                            defaultValue={client_data.email}
                                        />
                                    </div>

                                    <label
                                        htmlFor="email"
                                        className="pt-6 block text-sm font-medium text-gray-700"
                                    >
                                        Instagram Handle
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="instagram"
                                            id="instagram"
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="eg. bollywoofunknyc"
                                            onChange={onInstagramChange}
                                            required
                                            defaultValue={client_data.instagram}
                                        />
                                    </div>

                                    <div className="mt-12 text-right">
                                        <button
                                            type="button"
                                            className="inline-flex mr-3 justify-center px-4 py-2 text-sm font-medium text-gray-900 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                            onClick={closeModal}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                            onClick={handleClick}
                                        >
                                            {btnText}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
